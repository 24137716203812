<template>
    <div class="view p404">
		<div class="page-title">
			<h1>You seem a bit lost master...<br>Wanna play with me? 🐶</h1>
			<router-link class="btn" to="/">Maybe later...</router-link>
			<button v-if="!playing" class="btn valid" @click="startGame">Play!</button>
			<button v-if="playing" class="btn valid" @click="reload();">Try again</button>
		</div>
		<div ref="puzzle" class="puzzle" :preparing="preparing" :playing="playing">
			<img ref="image" :style="{
				top: `${Math.floor(i / 3) * 33.333333}%`,
				left: `${(i % 3) * 33.333333}%`
			}" v-for="(block, i) in blocks" :src="block" :key="`block-${i}`">
			<button :ready="selectedImage !== null && i === emptyBlock" :disabled="selectedImage === null && i === emptyBlock" class="block" :style="{
				top: `${Math.floor(i / 3) * 33.333333}%`,
				left: `${(i % 3) * 33.333333}%`
			}" v-for="(x, i) in 9" :key="`block-btn-${i}`"
			@click="selectImage(i)"></button>
		</div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
	name: "cart",
	components: {},
	computed: {
		...mapGetters({
			isMobile: 'getMobile'
		})
	},
	data() {
		return {
			images: [
				'https://images.unsplash.com/photo-1529927066849-79b791a69825?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1949&q=80',
				'https://images.unsplash.com/photo-1535909339361-ef56e179d637?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
				'https://images.unsplash.com/photo-1533260622290-f987053f3644?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1998&q=80',
				'https://images.unsplash.com/photo-1551172028-22ab2b51a045?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=80',
				'https://images.unsplash.com/photo-1551171340-f087dbbe3720?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=80',
				'https://images.unsplash.com/photo-1529959942810-ed07bb5f27d3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1984&q=80'
			],
			blocks: [],
			preparing: false,
			playing: false,
			emptyBlock: 0,
			selectedImage: null,
			success: false
		}
	},
	methods: {
		getRandom(min = 0, max = 0) {
			return Math.floor(Math.random()*(max-min+1)+min)
		},
		reload() {
			this.createGame().then(() => this.startGame())
		},
		createGame() {
			if(this.$refs.image) {
				this.$refs.image.map(img => {
					img.removeAttribute('selected')
					img.removeAttribute('discarded')
				})
			}
			const image = this.images[this.getRandom(0, this.images.length - 1)]
			this.$refs.puzzle.removeAttribute('style')
			return new Promise((resolve) => {
				const img = document.createElement('img')
				img.onload = () => {
					this.canvas.width = img.naturalWidth
					this.canvas.height = img.naturalHeight
					this.context.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight)
					const blocks = []
					const blockWidth = img.naturalWidth / 3
					const blockHeight = img.naturalHeight / 3
					const maxHeight = window.innerHeight * .5
					const ratio = img.naturalHeight / img.naturalWidth
					let width = maxHeight / ratio
					if(width > this.$el.clientWidth) {
						this.$refs.puzzle.style.width = '100%';
						this.$refs.puzzle.style.height = `${this.$refs.puzzle.clientWidth * ratio}px`
					}
					else this.$refs.puzzle.style.width = `${width}px`
					for(let i = 0; i < 3; i++) {
						for(let j = 0; j < 3; j++) {
							const data = this.context.getImageData(blockWidth * j, blockHeight * i, blockWidth, blockHeight)
							blocks.push(data)
						}
					}
					this.canvas.width = blockWidth
					this.canvas.height = blockHeight
					this.blocks = blocks.map(block => {
						this.context.putImageData(block, 0, 0)
						return this.canvas.toDataURL()
					})
					resolve()
				}
				img.crossOrigin = 'Anonymous'
				img.src = image
			})
		},
		startGame() {
			this.preparing = this.playing = true
			this.$nextTick(() => {
				this.shuffle(this.blocks.map((b, i) => i)).map((p, i) => {
					this.$refs.image[i].style.left = `${(p % 3) * 33.333333}%`
					this.$refs.image[i].style.top = `${Math.floor(p / 3) * 33.333333}%`
					this.$refs.image[i].setAttribute('index', p)
				})
				setTimeout(() => {
					this.emptyBlock = this.getRandom(0, this.blocks.length - 1)
					const img = this.$refs.puzzle.querySelector(`img[index="${this.emptyBlock}"]`)
					img.setAttribute('discarded', '')
					this.preparing = false
				}, 320)
			})
		},
		selectImage(i) {
			if(this.selectedImage === i) {
				const selected = this.$refs.puzzle.querySelector(`img[index="${this.selectedImage}"]`)
				selected.removeAttribute('selected')
				this.selectedImage = null
			}
			else if(this.emptyBlock === i && this.selectedImage !== null) {
				if(
					i - 3 === this.selectedImage ||
					i + 3 === this.selectedImage ||
					i - 1 === this.selectedImage ||
					i + 1 === this.selectedImage
				) {
					const img = this.$refs.puzzle.querySelector(`img[index="${this.selectedImage}"]`)
					img.style.left = `${(i % 3) * 33.333333}%`
					img.style.top = `${Math.floor(i / 3) * 33.333333}%`
					img.setAttribute('index', i)
					img.removeAttribute('selected')
					this.emptyBlock = this.selectedImage
					const empty = this.$refs.puzzle.querySelector(`img[discarded]`)
					empty.style.left = `${(this.emptyBlock % 3) * 33.333333}%`
					empty.style.top = `${Math.floor(this.emptyBlock / 3) * 33.333333}%`
					empty.setAttribute('index', this.emptyBlock)
					this.selectedImage = null
					if(this.isComplete()) {
						this.playing = false
						this.success = true
					}
				}
			}
			else if(this.emptyBlock !== i) {
				if(this.selectedImage !== null) {
					const selected = this.$refs.puzzle.querySelector(`img[index="${this.selectedImage}"]`)
					selected.removeAttribute('selected')
				}
				const img = this.$refs.puzzle.querySelector(`img[index="${i}"]`)
				img.setAttribute('selected', '')
				this.selectedImage = i
			}
		},
		isComplete() {
			return this.$refs.image.filter((img, i) => img.getAttribute('index') == i).length === 9
		},
		shuffle(array) {
			let counter = array.length
			while (counter > 0) {
				let index = Math.floor(Math.random() * counter)
				counter--
				let temp = array[counter]
				array[counter] = array[index]
				array[index] = temp
			}
			return array
		},
	},
	mounted: function() {
		this.canvas = document.createElement('canvas')
		this.context = this.canvas.getContext('2d')
		this.$nextTick(() => this.createGame())
		window.dispatchEvent(new CustomEvent('setLoading', {detail: {value: false}}))
	}
}
</script>

<style scoped>
.view {
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	justify-content: center;
	align-items: center;
}

.page-title {
	text-align: center;
}

.page-title h1 {
	font-size: 2.22vh;
}

.page-title .btn {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 12.59vh;
	height: 3.52vh;
	border: none;
	background: none;
	font-weight: bold;
	font-family: 'Inter';
	font-size: 1.11vh;
	transition: opacity .16s;
	/* opacity: .7; */
	border: 4px solid rgba(250, 250, 250, 0.33);
	margin: 1.11vh 16px;
}

.page-title .btn.valid {
	background: #208E7F;
	border-color: #155C52;
}

.puzzle {
	position: relative;
	min-height: 1px;
	height: 50vh;
	z-index: 1;
	perspective: 600px;
}

.puzzle .block,
.puzzle img {
	position: absolute;
	width: 33.333333%;
	height: 33.333333%;
}

.puzzle img {
	transform-origin: center bottom;
}

.puzzle img[selected] {
	transform: translateZ(50px);
	z-index: 2;
}

.puzzle img[discarded] {
	opacity: 0;
}

.puzzle[playing] img {
	border: 1px solid #221f1f;
	transition: top .32s ease-in-out, left .32s ease-in-out, opacity .32s ease-in, transform .32s ease-in-out, border .32s ease-in;
}

.puzzle .block {
	background: none;
	border: none;
	border-radius: 0;
	z-index: 3;
}

.puzzle .block[ready] {
	background: rgba(32, 142, 127, 0.5);
	z-index: 1;
}

@media screen and (max-width: 1023px) {
	.page-title h1 {
		font-size: 24px;
	}
	.page-title .btn {
		font-size: 12px;
		width: 134px;
		height: 38px;
		margin: 12px 16px;
	}
	.view {
		min-height: calc(100vh - 240px);
	}
}
</style>

