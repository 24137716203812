var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view p404"},[_c('div',{staticClass:"page-title"},[_vm._m(0),_c('router-link',{staticClass:"btn",attrs:{"to":"/"}},[_vm._v("Maybe later...")]),(!_vm.playing)?_c('button',{staticClass:"btn valid",on:{"click":_vm.startGame}},[_vm._v("Play!")]):_vm._e(),(_vm.playing)?_c('button',{staticClass:"btn valid",on:{"click":function($event){return _vm.reload();}}},[_vm._v("Try again")]):_vm._e()],1),_c('div',{ref:"puzzle",staticClass:"puzzle",attrs:{"preparing":_vm.preparing,"playing":_vm.playing}},[_vm._l((_vm.blocks),function(block,i){return _c('img',{key:`block-${i}`,ref:"image",refInFor:true,style:({
				top: `${Math.floor(i / 3) * 33.333333}%`,
				left: `${(i % 3) * 33.333333}%`
			}),attrs:{"src":block}})}),_vm._l((9),function(x,i){return _c('button',{key:`block-btn-${i}`,staticClass:"block",style:({
				top: `${Math.floor(i / 3) * 33.333333}%`,
				left: `${(i % 3) * 33.333333}%`
			}),attrs:{"ready":_vm.selectedImage !== null && i === _vm.emptyBlock,"disabled":_vm.selectedImage === null && i === _vm.emptyBlock},on:{"click":function($event){return _vm.selectImage(i)}}})})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_vm._v("You seem a bit lost master..."),_c('br'),_vm._v("Wanna play with me? 🐶")])
}]

export { render, staticRenderFns }